import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <Link to="/privacy-policy" className={styles.footerLink}>Privacy Policy</Link>
      <a href="mailto:admin@discoverdo.com.au" className={styles.footerLink}>
        Contact Us
      </a>
    </footer>
  );
}

export default Footer;