// PrivacyPolicy.tsx
import React from 'react';

export const PrivacyPolicy: React.FC = () => (
  <div className="privacy-policy" style={{ padding: '20px' }}>
    <h1>Privacy Policy</h1>
    
    <p><strong>Effective Date:</strong> 27 Oct 2024</p>
    
    <h2>1. Introduction</h2>
    <p>This Privacy Policy explains how <strong>DiscoverDo</strong> (“we”, “our”, or “us”) collects, uses, and protects personal information. By using <strong>DiscoverDo</strong>, you consent to the data practices described in this policy.</p>

    <h2>2. Information We Collect</h2>
    <p>We may collect the following types of information:</p>
    <ul>
      <li><strong>Personal Information:</strong> Includes details such as your name, email address, and phone number.</li>
      <li><strong>Usage Data:</strong> Information on how you interact with the app, including device type, IP address, and usage metrics.</li>
    </ul>

    <h2>3. How We Use Your Information</h2>
    <p>We use your data to:</p>
    <ul>
      <li>Provide and improve our services.</li>
      <li>Respond to inquiries or customer support requests.</li>
      <li>Personalize your experience within the app.</li>
      <li>Send important updates related to app usage, terms, or policies.</li>
    </ul>

    <h2>4. Data Sharing and Disclosure</h2>
    <p>We do <strong>not</strong> share your personal information with third parties except:</p>
    <ul>
      <li>To comply with legal obligations, including law enforcement requests.</li>
      <li>With your explicit consent, if required for third-party services that enhance app functionality.</li>
    </ul>

    <h2>5. Data Security</h2>
    <p>We implement industry-standard measures to protect your data from unauthorized access or disclosure. However, please note that no transmission over the internet is completely secure.</p>

    <h2>6. User Rights</h2>
    <p>Depending on your location, you may have the right to:</p>
    <ul>
      <li>Access the personal information we hold about you.</li>
      <li>Request correction or deletion of your personal information.</li>
      <li>Opt out of certain data collection or sharing practices.</li>
    </ul>

    <h2>7. Changes to This Policy</h2>
    <p>We may update this Privacy Policy periodically. Changes will be posted with an updated “Effective Date” at the top of the policy.</p>
  </div>
);